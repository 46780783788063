<template>
    <div>
        <h4 class="bold text-uppercase mb-3">
            <span @click="$router.go(-1)" class="font-weight-bold hand">
                <i class="fas fa-chevron-left mx-1"></i>
                {{ $route.name }}
            </span>
        </h4>
        <h4 class="bold text-uppercase">Cài đặt thông báo khi tạo đơn</h4>
        <div class="row mt-3">
            <div class="col-md-8 col-12">
                <div class="card card-gray">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 col-12">
                                <h6 class="bold">Tiêu đề thông báo</h6>
                            </div>
                            <div class="col">
                                <input
                                    class="form-control input-light"
                                    type="text"
                                    placeholder="Nhập tiêu đề thông báo"
                                    required=""
                                    v-model="siteUpdate.note_order_title"
                                />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-3 col-12">
                                <h6 class="bold">Nội dung thông báo</h6>
                            </div>
                            <div class="col">
                                <!-- <textarea class="form-control input-light" rows="15" v-model="siteUpdate.note_order_content" placeholder="Nhập nội dung cần thông báo"></textarea> -->
                                <ckeditor
                                    :editor="editor"
                                    v-model="siteUpdate.note_order_content"
                                    :config="editorConfig"
                                    @ready="onReady"
                                ></ckeditor>
                                <button
                                    @click="updateSiteInfo()"
                                    class="mt-3 btn-block btn-lg btn bold btn-dark-blue"
                                    type="submit"
                                >
                                    Lưu Thông Báo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0 col-12">
                <div class="card card-gray-2 h-100">
                    <div class="card-body">
                        <h4>Lưu ý:</h4>
                        <h6>Thông báo sẽ được hiển ở trang Tạo đơn mỗi khi khách hàng tạo đơn.</h6>
                        <h6>
                            Không muốn thông báo bạn hãy <span class="bold">Xóa</span> tiêu đề và nội dung sau đó Lưu.
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { updateSiteInfo } from "@/api/admin-agency-lv2"
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
export default {
    name: "notification-setting",
    data() {
        return {
            editor: DecoupledEditor,
            editorConfig: {
                placeholder: "Nhập nội dung thông báo",
                removePlugins: ["MediaEmbed", "ImageUpload", "Heading"]
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        site() {
            return this.$store.state.agency.site
        },
        agency() {
            return this.$store.state.agency
        }
    },
    created() {
        this.siteUpdate = Object.assign({}, this.site)
    },
    methods: {
        onReady(editor) {
            editor.ui
                .getEditableElement()
                .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement())
        },
        updateSiteInfo: async function() {
            let data = await updateSiteInfo(this.siteUpdate)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", data.message, "success")
                this.$store.dispatch("GET_SITE_AGENCY_INFO", this.agency.agency_domain)
            } else this.$swal("Lỗi", data.message, "error")
        }
    }
}
</script>
